import { Injectable } from '@angular/core';
import { TrademaxAPIService } from './trademaxapi.service';
import { BroadcastService } from './services/broadcast.service';
import { Utils } from './utils';
import { environment } from 'src/environments/environment';
import { ClientDetails, ClientDetailsCustomScript } from './models/client-details';
import { Step } from 'src/types/step';
import { UILogEvent } from 'src/types/UILogEvent';
//import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class CustomTrackingService {
  dealer_config: ClientDetails;
  constructor(
    private api: TrademaxAPIService,
    private broadcastService: BroadcastService
  ) {
    //expose some Utils to regular JS:
    window['Utils'] = {
      env: environment.name,
      injectScriptLink: Utils.injectScriptLink,
      injectScriptSource: Utils.injectScriptSource,
      logEvent: (codeName: string, eventName: string, eventData: any) => {
        //console.log(`LogEvent: ${eventName}, ${JSON.stringify(eventData)} `)
        const payload: UILogEvent = {
          codeName: codeName,
          apiKey: this.dealer_config.apikey,
          sessionId: this.dealer_config.session_id,
          userGuid: this.dealer_config.token,
          dealerId: this.dealer_config.bac,
          eventName: eventName,
          eventData: eventData,
        };
        this.api.logEvent(payload);
      },
    };
  }
  async init() {
    let stepName = null;
    //re-emit private app events as public events. Used for tracking by external scripts
    this.broadcastService.clientDetails.subscribe(async (details) => {
      if (!details) return;
      this.dealer_config = details;
      window['dealer_config'] = details;
      await this.loadCustomScripts(details.custom_scripts);

      //check if the customs_scripts have carbravo_go in them
      if (!details.custom_scripts.find((el) => el.codeName == 'carbravo_go')) {
        //carbravo is special. if its is in the url, always load the "cabravo_go script"
        const parts = window.location.href.split(/\/|\?|$/);
        if (parts.includes('carbravo')) {
          await this.loadCustomCarbravoScript();
        }
      }

      this.dispatchEvent('dealer_config', details);
      //dispatch step again because it is fired automatically
      if (stepName) this.dispatchEvent('step', { name: stepName });
    });
    this.broadcastService.stepEvent.subscribe((step: Step) => {
      stepName = step?.name;
      if (step) this.dispatchEvent('step', { name: step.name });
    });
    this.broadcastService.selectedVehicle.subscribe((details) => {
      this.dispatchEvent('vehicle_details', details);
    });
    this.broadcastService.vehicleSelectedOptions.subscribe((details) => {
      this.dispatchEvent('vehicle_options', details);
    });
    this.broadcastService.customQuestionsAnswers.subscribe((details) => {
      this.dispatchEvent('custom_questions', details);
    });
    this.broadcastService.vehicleConditionAnswers.subscribe((details) => {
      this.dispatchEvent('vehicle_condition', details);
    });
    this.broadcastService.consumerQuestionsAnswers.subscribe((details) => {
      this.dispatchEvent('consumer_questions', details);
    });
    this.broadcastService.leadReady.subscribe((details) => {
      this.dispatchEvent('lead', details);
    });
    this.broadcastService.tradeValueGTV.subscribe((details) => {
      this.dispatchEvent('lead_response', details);
    });
    this.broadcastService.tradeValueEST.subscribe((details) => {
      this.dispatchEvent('lead_response', details);
    });
    this.broadcastService.tradeValueTrader.subscribe((details) => {
      this.dispatchEvent('lead_response', details);
    });
    this.broadcastService.tradeValueError.subscribe((details) => {
      this.dispatchEvent('lead_error', details);
    });
  }

  dispatchEvent(eventName: string, eventData: object) {
    document.dispatchEvent(new CustomEvent(eventName, { detail: eventData }));
  }

  loadCustomScripts(scripts: ClientDetailsCustomScript[]): Promise<void[]> {
    const promises = scripts?.map((script) => {
      // deepcode ignore DOMXSS: We trust the source code loaded from our web service
      return Utils.injectScriptSource('head', `//${script.codeName}\n(function(){${script.sourceCode}})()`);
    });
    return Promise.all(promises);
  }

  async loadCustomCarbravoScript() {
    const src = await this.api.getCustomScriptItem('carbravo_go');
    await this.loadCustomScripts([src]);
  }
}
